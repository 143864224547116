let auditStateConfig = [
    {
        state: "0",
        name: "审核中",
        backGroundColor:'#F9ECDEdc',
        textColor:"#D9720B",
        icon:"icon-more"
    },
    {
        state: "1",
        name: "上传成功",
        backGroundColor:'#DDF2E4dc',
        textColor:"#12B355",
        icon:"icon-dui"
    },
    {
        state: "2",
        name: "审核失败",
        backGroundColor:'#F5E3E4dc',
        textColor:"#E64545",
        icon:"icon-Cross"
    }
]

export { auditStateConfig }