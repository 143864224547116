export default {
    "messageList": [
        {
            code: ['400', '401', '500', '501'],
            message: '系统繁忙，请稍后再试。'
        },
        {
            code: ['502', '509'],
            message: '您暂时无此权限，若需要访问，请联系管理员开通。'
        },
        {
            code: ['5014', '5015'],
            message: '您的登录信息存在异常，麻烦您重新登录试试。'
        },
        {
            code: ['505'],
            message: '您的信息存在异常，请联系管理员进行完善。'
        },
        {
            code: ['506', '507', '508'],
            message: '系统正在更新，请您稍后访问。'
        },
    ]
}
