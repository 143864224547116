const THEME_CONFIG = [
    {
        mode: "default",
        name: '默认',
        layout: "mix",
        brandTheme: "#3C7FFF",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/default/header.png)",
        headerActive: "#FEA71D",
        headerText: '#FFFFFF',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/default/body.png)",
        sildBarBc: "#EDF0F7",
        sildBarActive: "#3C7FFF",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: true,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/default/typicalExample.png)'
    },
    {
        mode: "colorfulSpringLights",
        name: '炫彩春光',
        layout: "mix",
        brandTheme: "#0F8D99",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/colorfulSpringLights/header.png)",
        headerActive: "#FCFC32",
        headerText: '#FFFFFF',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/colorfulSpringLights/body.png)",
        sildBarBc: "#E6F4F5",
        sildBarActive: "#0F8D99",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: true,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/colorfulSpringLights/typicalExample.png)'
    },
    {
        mode: 'greenSeaSchool',
        name: '绿海学堂',
        layout: "mix",
        brandTheme: "#0F8D99",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenSeaSchool/header.png)",
        headerActive: "#FCFC32",
        headerText: '#FFFFFF',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenSeaSchool/body.png)",
        sildBarBc: "#E6F4F5",
        sildBarActive: "#0F8D99",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: true,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenSeaSchool/typicalExample.png)'
    },
    {
        mode: 'spiritualMystery',
        name: '灵动秘境',
        layout: "mix",
        brandTheme: "#5A4BFA",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/spiritualMystery/header.png)",
        headerActive: "#FCFC32",
        headerText: '#FFFFFF',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/spiritualMystery/body.png)",
        sildBarBc: "#E7E6F5",
        sildBarActive: "#5A4BFA",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: false,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/spiritualMystery/typicalExample.png)'
    },
    {
        mode: 'greenness',
        name: '绿意盎然',
        layout: "mix",
        brandTheme: "#4C733F",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenness/header.png)",
        headerActive: "#FCFC32",
        headerText: '#FFFFFF',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenness/body.png)",
        sildBarBc: "#E2EBDF",
        sildBarActive: "#4C733F",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: true,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenness/typicalExample.png)'
    },
    {
        mode: 'dazzlingSailing',
        name: '炫动起航',
        layout: "mix",
        brandTheme: "#3C7FFF",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/dazzlingSailing/header.png)",
        headerActive: "#3C7FFF",
        headerText: '#333333',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/dazzlingSailing/body.png)",
        sildBarBc: "#F5F7FA",
        sildBarActive: "#3C7FFF",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: true,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/dazzlingSailing/typicalExample.png)'
    }
];

export default THEME_CONFIG;
