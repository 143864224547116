export default {
    baseData: {
        // 头部筛选
        formData: {
            btnFormType: true,
            data: [{
                type: 'datePick',
                label: '日期范围',
                value: '',
                placeholder: '请输入',
                key: 'dateRange'
            }, {
                type: 'select',
                label: '体温状态',
                value: '',
                placeholder: '请选择',
                key: 'temperatureState',
                list: [
                    {
                        label: '正常',
                        value: 1
                    },
                    {
                        label: '异常',
                        value: 0
                    },
                ]
            }, {
                type: 'treeSelect',
                label: '地点',
                value: '',
                placeholder: '请选择地点',
                key: 'addressName',
                width: '250px',
                defaultProps: {
                    children: 'child',
                    label: 'addressName'
                },
                list: []
            }, {
                type: 'select',
                label: '身份',
                value: '',
                placeholder: '请选择',
                key: 'personIdentity',
                list: [
                    {
                        label: '全部',
                        value: ''
                    },
                    {
                        label: '学生',
                        value: '1'
                    },
                    {
                        label: '教职工',
                        value: '2'
                    }, {
                        label: '陌生人',
                        value: '3'
                    },{
                        label: '家长',
                        value: '4'
                    },
                ]
            }, {
                type: 'select',
                label: '班级/部门',
                value: '',
                placeholder: '请选择',
                key: 'organId',
                list: []
            }, {
                type: 'input',
                label: '姓名',
                key: 'personName',
                placeholder: '请输入',
            }],
            btnList: [{
                type: "enquiry",
                text: '查询',
                fn: 'primary',
                auth:['temperatureMonitor:list']
            }, {
                type: "enquiry",
                text: '重置',
                fn: 'reset'
            },
            ]
        },
        // 表格配置
        table_config: {
            thead: [
                {
                    label: "抓拍图",
                    prop: "snapshotImg",
                    type: "images",
                    showPreview: true,
                    width: '72px',
                    height: '40px',
                    borderRadius: 'none'
                }, {
                    label: "身份",
                    type: "function",
                    callBack:(row,prop) => {
                        switch (row.personIdentity) {
                            case "1":
                                return "学生";
                            case "2":
                                return "教职工";
                            case "3":
                                return "陌生人";
                            case "4":
                                return "家长";
                            default:
                                return "--"
                        }
                    }
                },
                {
                    label: "姓名",
                    prop: "personName",
                },
                {
                    label: "班级/部门",
                    prop: "organ",
                    type:'popoverText',
                },
                {
                    label: "体温",
                    type: "function",
                    prop: "temperature",
                    callBack:(row,prop) => {
                        if (row.temperatureState === '0') {
                            return `<div style="color: #f56c6c">${row.temperature}</div>`;
                        } else {
                            return row.temperature
                        }
                    }
                },
                {
                    label: "状态",
                    type: "function",
                    callBack:(row,prop) => {
                        switch (row.temperatureState) {
                            case "0":
                                return `<div style="color: #f56c6c">异常</div>`;
                            case "1":
                                return "正常";
                            default:
                                return "--"
                        }
                    }
                }, {
                    label: "地点",
                    prop: "addressName",
                    type:'popoverText',
                }, {
                    label: "检测时间",
                    prop: "createTime",
                    labelWidth: '160'

                }
            ],
            check: true,
            height: '',
            isCloseRow: {key: 'status', value: '0'}
        },
    }
}
