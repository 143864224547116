// 教学资源对接，请求接口跳转
const configure = [
    {
        id: '0',
        jumpUrlSchoolId: '1110',
        jumpUrlName: '学科网'
    },
    {
        id: '1',
        jumpUrlSchoolId: 'c655ce9054df89abd741d1d52f32c248',
        jumpUrlName: '学科网'
    },
    {
        id: '2',
        jumpUrlSchoolId: '4a7c31a537420f2baf322557427e81a2',  //景海道
        jumpUrlName: '学科网'
    },
];

// 组卷网
const zjwConfig = [
    {
        id: '0',
        jumpUrlSchoolId: '4a7c31a537420f2baf322557427e81a2', //景海道
        jumpUrlName: '组卷网'
    },
];

// 对照表
const comparisonTable = [
    {
        id: '66e2ee5c56ea4376831cac7d56b429b9',
        name: '正确云',
        fnName: 'rC',
        listId: '66e2ee5c56ea4376831cac7d56b429b9',
        url: 'https://member.zqy.com/xinkao/login'
    }
]

export {
    configure,
    comparisonTable,
    zjwConfig
} 
