const BTN_MENUS = [
    {
        btn: [
            "teacherBase:documentTemplate", // 教学教研-档案管理-教职工档案-编辑档案模板
            "studentBase:documentTemplate", // 教学教研-档案管理-学生档案-编辑档案模板
        ],
        menu: {
            link: "/summary/archives/summaryStudentInfoTemplate",
            menuId: "0304020108",
            name: "SummaryStudentInfoTemplate",
            orderNum: 3,
            parentId: "0101",
            path: "summaryStudentInfoTemplate",
            platType: "2",
            title: "档案模板",
        },
    },
    {
        btn: [
            "teacherBase:documentShar", // 教学教研-档案管理-教职工档案-个人档案权限
            "studentBase:documentShar", // 教学教研-档案管理-学生档案-个人档案权限
        ],
        menu: {
            link: "/summary/archives/summaryStudentInfoShare",
            menuId: "0304010107",
            name: "SummaryStudentInfoShare",
            orderNum: 4,
            parentId: "0101",
            path: "summaryStudentInfoShare",
            platType: "2",
            title: "档案权限",
        },
    },
    {
        btn: [
            "studentBase:documentStu", // 教学教研-档案管理-学生档案-学生档案
            "studentBase:edit", // 教学教研-档案管理-学生档案-编辑
        ],
        menu: {
            link: "/summary/archives/summaryStudentInfo",
            menuId: "0304020109",
            name: "SummaryStudentInfo",
            orderNum: 2,
            parentId: "0101",
            path: "summaryStudentInfo",
            platType: "2",
            title: "档案详情",
        },
    },
    {
        btn: [
            "checkStudentStatus", // 人员信息--学生管理-学生状态
        ],
        menu: {
            link: "/summary/archives/summaryStudentStatusInfo",
            menuId: "0304090909",
            name: "SummaryStudentStatusInfo",
            orderNum: 2,
            parentId: "0101",
            path: "summaryStudentStatusInfo",
            platType: "2",
            title: "历史记录详情",
        },
    },
];

const FIRST_PAGE = {
    menuId: "000000",
    title: "工作台",
    path: "/firstPage",
    name: "FirstPage",
    platType: "2",
};

const FIRST_PAGE_HOME = {
    menuId: "000100",
    title: "工作台",
    parentId: "000000",
    path: "home",
    link: "/firstPage/home",
    name: "firstPageHome",
    platType: "2",
}

const LEFT_SLIDER_HIDDEN_ROUTER = [
    {
        link: "/summary/archives/summaryStudentInfoShare",
        menuId: "0304010107",
        name: "SummaryStudentInfoShare",
        orderNum: 4,
        parentId: "0101",
        path: "summaryStudentInfoShare",
        platType: "2",
        title: "档案权限",
    },
    {
        link: "/summary/archives/summaryStudentInfoTemplate",
        menuId: "0304020108",
        name: "SummaryStudentInfoTemplate",
        orderNum: 3,
        parentId: "0101",
        path: "summaryStudentInfoTemplate",
        platType: "2",
        title: "档案模板",
    },
    {
        link: "/summary/archives/summaryStudentInfo",
        menuId: "0304020109",
        name: "SummaryStudentInfo",
        orderNum: 2,
        parentId: "0101",
        path: "summaryStudentInfo",
        platType: "2",
        title: "档案详情",
    },
    {
        link: "/summary/archives/summaryStudentStatusInfo",
        menuId: "0304090909",
        name: "SummaryStudentStatusInfo",
        orderNum: 2,
        parentId: "0101",
        path: "summaryStudentStatusInfo",
        platType: "2",
        title: "历史记录详情",
    },
]


export {
    BTN_MENUS,
    FIRST_PAGE,
    FIRST_PAGE_HOME,
    LEFT_SLIDER_HIDDEN_ROUTER
}
