export default {
    1: {
        'formData': {
            'data': [
                {
                    type: 'datePick',
                    dateType: 'daterange',
                    valueFormat: 'yyyy-MM-dd',
                    format: 'yyyy-MM-dd',
                    startPlaceholder: '入库开始日期',
                    endPlaceholder: '入库结束日期',
                    value: '',
                    key: 'time',
                },
                {
                    type: 'select',
                    placeholder: '资产品类',
                    key: 'categoryId',
                    value: '',
                    list: [],
                },
                {
                    type: 'input',
                    placeholder: '资产名称',
                    key: 'assetsName',
                    value: '',
                },
                {
                    type: 'input',
                    placeholder: '供应商名称',
                    key: 'assetsSupplier',
                    value: '',
                }
            ],
            'btnList': [
                {
                    type: 'primary',
                    text: '查询',
                    fn: 'primary',
                },
                {
                    type: 'enquiry',
                    text: '重置',
                    fn: 'reset',
                },
                // {
                //     type: 'success',
                //     text: '导出',
                //     fn: 'export'
                // },
            ],
            btnFormType: true,
            closeWidth: 850
        },
        'table_config': {
            thead: [
                {
                    label: "入库日期",
                    prop: "createTime",
                    labelWidth: '160',
                    align: "center",
                    type: "function",
                        callBack(row) {
                            return row.createTime ? row.createTime.slice(0, 16):'-'
                        },
                },
                {
                    label: "资产编号",
                    prop: "assetsCode",
                    type:'popoverText',
                    labelWidth: '160',
                    align: "center",
                },
                {
                    label: "资产名称",
                    prop: "assetsName",
                    type:'popoverText',
                    labelWidth: '120',
                    align: "center",
                },
                {
                    label: "品牌",
                    prop: "assetsBrand",
                    type:'popoverText',
                    labelWidth: '100',
                    align: "center",
                    className: 'text-spacing-reduction'
                },
                {
                    label: "型号",
                    prop: "assetsModel",
                    type:'popoverText',
                    labelWidth: '120',
                    align: "center",
                    className: 'text-spacing-reduction'
                },
                {
                    label: "序列号",
                    prop: "assetsSerialNumber",
                    type:'popoverText',
                    labelWidth: '150',
                    align: "center",
                },
                {
                    label: "资产品类",
                    prop: "categoryName",
                    type:'popoverText',
                    labelWidth:'100',
                    align: "center",
                },
                {
                    label: "采购日期",
                    prop: "buyTime",
                    labelWidth: '160',
                    align: "center",
                    type: "function",
                        callBack(row) {
                            return row.buyTime ? row.buyTime.slice(0, 16):'-'
                        },
                },
                {
                    label: "供应商",
                    prop: "assetsSupplier",
                    type:'popoverText',
                    labelWidth: '150',
                    align: "center",
                },
                {
                    type: "function",
                    label: "入库单价",
                    prop: "unitPrice",
                    align: "right",
                    labelWidth:'100',
                    callBack(row) {
                        if (row.unitPrice) {
                            return row.unitPrice.toLocaleString()
                        } else {
                            return 0
                        }
                    }
                },
                {
                    label: "计量单位",
                    prop: "assetsUnit",
                    type:'popoverText',
                    align: "center",
                    labelWidth: '160'
                },
                {
                    label: "入库类型",
                    prop: "recordType",
                    type: "function",
                    align: "center",
                    callBack(row) {
                        return {
                            1: '入库',
                            2: '出库',
                            3: '保修',
                            4: '归还',
                            5: '报废',
                        } [+row.recordType]
                    }
                },
                {
                    label: "入库数量",
                    prop: "operateNumber",
                    align: "center",
                    type:'popoverText',
                    labelWidth:'100px',
                },
                {
                    type: "function",
                    label: "入库总额",
                    prop: "totalPrice",
                    align: "right",
                    labelWidth:'100px',
                    callBack(row) {
                        if (row.totalPrice) {
                            return row.totalPrice.toLocaleString()
                        } else {
                            return 0
                        }
                    }
                },
                {
                    label: "操作",
                    type: "slot",
                    slotName: "operation",
                    labelWidth:'150px',
                }
            ],
        },
    },
    2: {
        'formData': {
            data: [
                {
                    type: 'datePick',
                    dateType: 'daterange',
                    valueFormat: 'yyyy-MM-dd',
                    format: 'yyyy-MM-dd',
                    startPlaceholder: '出库开始日期',
                    endPlaceholder: '出库结束日期',
                    value: '',
                    key: 'time',
                },
                {
                    type: 'select',
                    key: 'categoryId',
                    placeholder: '资产品类',
                    value: '',
                    list: [],
                },
                {
                    type: 'input',
                    placeholder: '资产名称',
                    key: 'assetsName',
                    value: '',
                    list: []
                },
                {
                    type: 'select',
                    placeholder: '领用部门/人',
                    key: 'collectPerson',
                    value: '',
                    list: [],
                }
            ],
            btnList: [
                {
                    type: 'primary',
                    text: '查询',
                    fn: 'primary',
                },
                {
                    type: 'enquiry',
                    text: '重置',
                    fn: 'reset',
                },
                // {
                //     type: 'success',
                //     text: '导出',
                //     fn: 'export'
                // },
            ],
            btnFormType: true,
            closeWidth: 850
        },
        'table_config': {
            thead: [
                {
                    label: "出库日期",
                    prop: "operateTime",
                    labelWidth: '160',
                    align: "center",
                    type: "function",
                        callBack(row) {
                            return row.createTime ? row.createTime.slice(0, 16):'-'
                        }
                },
                {
                    label: "领用部门/人",
                    labelWidth: '160',
                    type: "function",
                    align: "center",
                    callBack(row) {
                        return row.collectDeptName + row.collectPersonName
                    }
                },
                {
                    label: "出库原因",
                    prop: "operateReason",
                    type: 'popoverText',
                    align: "center"
                },
                {
                    label: "资产编号",
                    prop: "assetsCode",
                    type:'popoverText',
                    align: "center"
                },
                {
                    label: "资产名称",
                    prop: "assetsName",
                    type:'popoverText',
                    align: "center"
                },
                {
                    label: "品牌",
                    prop: "assetsBrand",
                    type:'popoverText',
                    align: "center",
                    className: 'text-spacing-reduction'
                },
                {
                    label: "型号",
                    prop: "assetsModel",
                    type:'popoverText',
                    align: "center",
                    className: 'text-spacing-reduction'
                },
                {
                    label: "序列号",
                    prop: "assetsSerialNumber",
                    type:'popoverText',
                    align: "center"
                },
                {
                    label: "资产品类",
                    prop: "categoryName",
                    type:'popoverText',
                    align: "center"
                },
                {
                    label: "数量",
                    prop: "operateNumber",
                    align: "center",
                    className: 'text-spacing-reduction'
                },
                {
                    label: "计量单位",
                    prop: "assetsUnit",
                    align: "center",
                },
                {
                    type: "function",
                    label: "出库总额",
                    prop: "totalPrice",
                    align: "right",
                    labelWidth: '160',
                    callBack(row) {
                        if (row.totalPrice) {
                            return row.totalPrice.toLocaleString()
                        } else {
                            return 0
                        }
                    }
                },{
                    label: "操作",
                    type: "slot",
                    slotName: "operation",
                    labelWidth:'150px',
                }
            ],
        },
    },
    3: {
        'formData': {
            data: [

                {
                    type: 'datePick',
                    dateType: 'daterange',
                    valueFormat: 'yyyy-MM-dd',
                    format: 'yyyy-MM-dd',
                    startPlaceholder: '报修开始日期',
                    endPlaceholder: '报修结束日期',
                    value: '',
                    key: 'time',
                },
                {
                    type: 'select',
                    placeholder: '资产品类',
                    key: 'categoryId',
                    value: '',
                    list: [],
                },
                {
                    type: 'input',
                    placeholder: '资产名称',
                    key: 'assetsName',
                    value: '',
                    list: []
                },
                {
                    type: 'input',
                    placeholder: '报修人员',
                    // key: 'assetsSupplier',
                    key:'declarer',
                    value: '',
                },
                {
                    type: 'input',
                    placeholder: '维修人员',
                    key: 'fixPerson',
                    value: '',
                }
            ],
            btnList: [
                {
                    type: 'primary',
                    text: '查询',
                    fn: 'primary',
                },
                {
                    type: 'enquiry',
                    text: '重置',
                    fn: 'reset',
                },
                // {
                //     type: 'success',
                //     text: '导出',
                //     fn: 'export'
                // },
            ],
            btnFormType: true,
            closeWidth: 1020
        },
        'table_config': {
            thead: [
                {
                    label: "报修日期",
                    prop: "createTime",
                    labelWidth: '160',
                    align: "center",
                    type: "function",
                        callBack(row) {
                            return row.createTime ? row.createTime.slice(0, 16):'-'
                        },
                },
                {
                    label: "报修人员",
                    prop: "declarer",
                    type:'popoverText',
                    align: "center",
                },
                {
                    label: "资产编号",
                    prop: "assetsCode",
                    type:'popoverText',
                    align: "center",
                },
                {
                    label: "资产名称",
                    prop: "assetsName",
                    type:'popoverText',
                    align: "center",
                },
                {
                    label: "品牌",
                    prop: "assetsBrand",
                    type:'popoverText',
                    align: "center",
                    className: 'text-spacing-reduction'
                },
                {
                    label: "型号",
                    prop: "assetsModel",
                    type:'popoverText',
                    align: "center",
                    className: 'text-spacing-reduction'
                },
                {
                    label: "序列号",
                    prop: "assetsSerialNumber",
                    type:'popoverText',
                    align: "center",
                },
                {
                    label: "资产品类",
                    prop: "categoryName",
                    type:'popoverText',
                    align: "center",
                },
                {
                    label: "维修日期",
                    prop: "newOperateTime",
                    labelWidth: '160',
                    align: "center",
                },
                {
                    label: "维修人员",
                    prop: "fixPerson",
                    type:'popoverText',
                    align: "center",
                },
                {
                    label: "维修内容",
                    prop: "fixContent",
                    type: 'popoverText',
                    align: "center",
                },
                {
                    label: "维修数量",
                    prop: "operateNumber",
                    align: "center",
                },
                {
                    type: 'function',
                    label: "维修金额",
                    prop: "fixAmount",
                    align: "right",
                    callBack(row) {
                        if (row.fixAmount) {
                            return row.fixAmount.toLocaleString()
                        } else {
                            return 0
                        }
                    }
                },
                {
                    label: "",
                    prop: "",
                    labelWidth:'26px',
                }
            ],
        },
    }
}
